import React from 'react';
import "./banner.css";

const Banner = () => {

    return (
    <div className="custom-banner-container">
        <div className="custom-banner-content">
            Your Beneficial Ownership Information Report (BOIR) is due! We can file it for you!
            &emsp; &emsp; &emsp;
            <a
                href="https://www.l4sb.com/services/beneficial-ownership-information-boi-report/"
                target="_blank"
                className="custom-banner-link"
                rel="noopener noreferrer"
            >
                <u>Get Started Now</u>
            </a>
        </div>
    </div>
)};

export default Banner;
